import React from 'react';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box, Column, Columns, Section } from './bulma';

export default function CompareSection({ items, box }) {
	let i = 0;
	return (
		<section>
			{items &&
				items.map(({ image, title, text, compare }) => (
					<Section>
						<div className="tile is-ancestor">
							<div className="tile is-vertical">
								<div className="tile is-parent">
									<div className="tile is-child">
										<article className="tile is-child notification is-white has-text-centered">
											<h2 className="title is-size-2">{title}</h2>
											<h3 className="subtitle is-4">{text}</h3>
										</article>
									</div>
								</div>
								<div className="tile">
									<div className="tile is-parent">
										<article className="tile is-child notification is-primary">
											<Section>
												<p className="title is-size-1 has-text-centered">{compare.ninja}</p>
											</Section>
										</article>
									</div>
									<div className="tile is-parent">
										<article className="tile is-child notification">
											<Section>
												<p className="title is-size-1 has-text-centered">
													{compare.competitor}
												</p>
											</Section>
										</article>
									</div>
								</div>
							</div>
						</div>
					</Section>
				))}
		</section>
	);
}
