import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

import Hero from '../components/Hero';
import Pitch from '../components/Pitch';
import CompareSection from '../components/CompareSection';
import BlogSection from '../components/BlogSection';
import CTA from '../components/CTA';
import SEO from '../components/SEO/SEO';

import { Section } from '../components/bulma';

export default function PartnerPage({ data }) {
	const { markdownRemark: page } = data;
	const { title, description, image, heading, subheading, pitch, sections, cta, slug } = page.frontmatter;

	return (
		<Layout>
			<SEO
				title={title}
				description={description}
				// image={image.childImageSharp.sizes.src}
				pathname={slug}
				article
			/>
			<ComparePageTemplate
				title={title}
				description={description}
				image={image}
				heading={heading}
				subheading={subheading}
				pitch={pitch}
				sections={sections}
				cta={cta}
				slug={slug}
			/>
		</Layout>
	);
}

export const ComparePageTemplate = ({
	image,
	title,
	description,
	heading,
	subheading,
	pitch,
	sections,
	cta,
	partner,
	slug
}) => (
	<div>
		<Hero title={heading} subtitle={subheading} />

		<Section>
			<div className="has-text-centered notification">
				<h2 className="title is-spaced">{pitch.title}</h2>
				<h3 className="subtitle">{pitch.text}</h3>
			</div>
		</Section>

		<CompareSection items={sections} box />
		<Section>
			<CTA />
		</Section>
		{/* <BlogSection /> */}
	</div>
);

export const pageQuery = graphql`
	query ComparePageByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			fields {
				slug
			}
			frontmatter {
				title
				description
				heading
				subheading
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
				pitch {
					title
					text
					cta
					link
				}
				sections {
					title
					text
					compare {
						ninja
						competitor
					}
					image {
						childImageSharp {
							fluid(maxWidth: 240, quality: 64) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				cta {
					title
					text
					label
				}
			}
		}
	}
`;
